<template>
  <page
    minimal
    :title="title"
    :apiStatusList="[fetchTestPreviewStatus]"
    :errorMessage="error"
  >
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" class="pa-1">
        <v-card v-if="isInvited" elevation="0" height="00">
          <v-card-text>
            <v-alert type="success" color="primary" elevation="2" class="mb-5">
              You've been invited to participate in the test.
            </v-alert>
            <p>
              You can start the test by clicking the link below or copying it to
              your clipboard.
            </p>
            <v-btn
              color="primary"
              dark
              x-large
              :href="testUrl"
              target="_blank"
              class="mb-4"
            >
              Start Test
            </v-btn>
            <div class="d-flex align-center">
              <v-text-field
                readonly
                :value="testUrl"
                label="Test URL"
                class="mr-2"
              ></v-text-field>
              <v-btn color="primary" @click="copyToClipboard"> Copy URL </v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-card v-else-if="successfulInvite" elevation="0" height="800">
          <v-row class="fill-height" align="center" justify="center">
            <v-col class="text-center">
              <v-card-title class="text-h5 justify-center">
                <v-icon color="primary" x-large class="mr-4 mb-5">
                  {{ mdiCheckDecagram }}
                </v-icon>
                <div
                  style="
                    word-break: normal;
                    overflow-wrap: normal;
                    line-height: 1.6;
                  "
                >
                  <h2><strong>Success!</strong></h2>
                  <p>
                    We've sent an <strong>invitation</strong> to
                    <strong>{{ email }}</strong> from
                    <strong>hello@codedrills.io</strong>.
                  </p>
                  <h3><strong>Next Steps:</strong></h3>
                  <ul align="left">
                    <li>
                      Please check your <strong>inbox</strong> (and spam folder
                      just in case) for the test invite.
                    </li>
                    <li>
                      Prepare for the test using our
                      <strong
                        ><a href="/signup/test-gbtf4z/testInvite"
                          >sample</a
                        ></strong
                      >.
                    </li>
                    <li>
                      Ensure you have a stable internet connection and chrome
                      browser installed.
                    </li>
                  </ul>
                  <p>
                    If you have any issues or questions, please contact us at
                    <strong
                      ><a href="mailto:hello@codedrills.io"
                        >hello@codedrills.io</a
                      ></strong
                    >.
                  </p>
                  <p>
                    We're <strong>excited</strong> to see your skills in action!
                  </p>
                </div>
              </v-card-title>
            </v-col>
          </v-row>
        </v-card>
        <v-form v-model="form" ref="form" lazy-validation v-else>
          <v-card elevation="0">
            <v-card-title> Candidate Details: </v-card-title>
            <v-card-text>
              <v-text-field
                label="Name"
                v-model="name"
                placeholder="Enter Name"
                outlined
                :rules="[(v) => !!v || 'Name is required']"
                :counter="50"
              >
                <template v-slot:append-outer>
                  <v-icon v-if="name" color="green">{{
                    mdiCheckCircle
                  }}</v-icon>
                </template>
              </v-text-field>
              <v-text-field
                label="Email"
                v-model="email"
                placeholder="email@domain.com"
                outlined
                :rules="emailRules"
              >
                <template v-slot:append-outer>
                  <v-icon
                    v-if="email && /.+@.+\..+/.test(email)"
                    color="green"
                    >{{ mdiCheckCircle }}</v-icon
                  >
                </template>
              </v-text-field>
            </v-card-text>
            <v-card-actions class="justify-center pb-8">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-alert
                      v-if="showError"
                      type="error"
                      dense
                      dismissible
                      :icon="mdiAlert"
                      @input="closeError"
                    >
                      {{ submitError }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row class="mt-4">
                  <v-col cols="12" :offset-md="4">
                    <v-btn
                      @click="validateForm"
                      class="rounded-md text-capitalize pa-5 white--text font-weight-medium text-h6"
                      color="success"
                      :disabled="!form || loading"
                      :loading="loading"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="showError">
                  <v-col cols="12">
                    <p>
                      You've already been <strong>invited</strong> to the test
                      with the email <strong>{{ email }}</strong> from
                      <strong>hello@codedrills.io</strong>.
                    </p>
                    <h3><strong>Next Steps:</strong></h3>
                    <ul align="left">
                      <li>
                        Please check your <strong>inbox</strong> (and spam
                        folder just in case) for the test invite.
                      </li>
                      <li>
                        Prepare for the test using our
                        <strong
                          ><a href="/signup/test-gbtf4z/testInvite"
                            >sample</a
                          ></strong
                        >.
                      </li>
                      <li>
                        Ensure you have a stable internet connection and chrome
                        browser installed.
                      </li>
                    </ul>
                    <p>
                      If you have any issues or questions, please contact us at
                      <strong
                        ><a href="mailto:hello@codedrills.io"
                          >hello@codedrills.io</a
                        ></strong
                      >.
                    </p>
                    <p>
                      We're <strong>excited</strong> to see your skills in
                      action!
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </page>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { mdiCheckDecagram, mdiCheckCircle, mdiAlert } from '@mdi/js'
import Page from '../components/Page'

export default {
  data() {
    return {
      mdiCheckCircle,
      mdiCheckDecagram,
      mdiAlert,
      form: false,
      name: '',
      email: '',
      emailRules: [
        (v) => !!v || 'Email needed',
        (v) =>
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'Email must be valid',
      ],
      successfulInvite: false,
      loading: false,
      error: null,
      isInvited: false,
      testUrl: null,
      testPreview: null,
      submitError: null,
      showError: false,
    }
  },
  methods: {
    ...mapActions('recruiter', ['testInviteByUrl']),
    ...mapActions('candidate', ['checkCandidateInvite', 'fetchTestPreview']),
    validateForm() {
      this.$refs.form.validate() && this.invite()
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.testUrl)
        this.$store.dispatch('notifs/addNotif', {
          text: `Copied URL to clipboard!`,
          type: 'success',
        })
      } catch (err) {
        this.$store.dispatch('notifs/addNotif', {
          text: `Erropr copying URL, try again!`,
          type: 'error',
        })
      }
    },
    invite() {
      this.loading = true
      this.showError = false
      this.testInviteByUrl({
        name: this.name,
        email: this.email,
        url: this.$route.params.testurl,
      })
        .then((_) => {
          this.successfulInvite = true
        })
        .catch((err) => {
          if (err.code === 6) {
            this.submitError =
              this.email +
              ' has already been invited to ' +
              this.testPreview.getTitle() +
              '!'
            this.showError = true
          } else {
            this.$store.dispatch('notifs/addNotif', {
              text:
                err.message || `Error Inviting ${this.name}, please try again!`,
              type: 'error',
            })
          }
        })
        .finally(() => (this.loading = false))
    },
    closeError() {
      this.showError = false
    },
    getTestPreview() {
      this.fetchTestPreview({
        url: this.$route.params.testurl,
        type: 'TEST',
      })
        .then((res) => {
          console.log('Fetched content..', res.getTestPreview())
          this.testPreview = res.getTestPreview()
        })
        .catch((err) => {
          if (err.code === 5) {
            this.error =
              "We're sorry, but the test you're trying to access was not found. Please ensure you have the correct link. If the problem persists, please contact the recruiter for further assistance."
          }
        })
    },
    prefillForm() {
      if (this.user) {
        console.log('prefilling form..', this.user)
        this.name = this.user.displayName || this.profile?.getName() || ''
        this.email = this.user.email
      }
    },
    isCandidateInvited() {
      this.checkCandidateInvite({
        url: this.$route.params.testurl,
        type: 'TEST',
      })
        .then((res) => {
          this.isInvited = res.getIsInvited()
          this.testUrl = res.getCandidateTestLink()
          console.log('isInvited', this.isInvited)
        })
        .catch((err) => {
          console.log('Error checking invite', err)
        })
    },
  },
  computed: {
    ...mapState('candidate', ['fetchTestPreviewStatus']),
    ...mapState('user', ['user', 'profile']),
    title() {
      if (!this.error)
        return 'Register for Test : ' + this.testPreview?.getTitle()
      return 'Test Not Found'
    },
  },
  mounted() {
    this.getTestPreview()
    this.prefillForm()
    this.isCandidateInvited()
  },
  created() {
    this.getTestPreview()
  },
  components: {
    Page,
  },
  watch: {
    user: function () {
      this.isCandidateInvited()
      this.prefillForm()
    },
    profile: function () {
      this.prefillForm()
    },
  },
}
</script>
<style scoped></style>
