var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page', {
    attrs: {
      "minimal": "",
      "title": _vm.title,
      "apiStatusList": [_vm.fetchTestPreviewStatus],
      "errorMessage": _vm.error
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm.isInvited ? _c('v-card', {
    attrs: {
      "elevation": "0",
      "height": "00"
    }
  }, [_c('v-card-text', [_c('v-alert', {
    staticClass: "mb-5",
    attrs: {
      "type": "success",
      "color": "primary",
      "elevation": "2"
    }
  }, [_vm._v(" You've been invited to participate in the test. ")]), _c('p', [_vm._v(" You can start the test by clicking the link below or copying it to your clipboard. ")]), _c('v-btn', {
    staticClass: "mb-4",
    attrs: {
      "color": "primary",
      "dark": "",
      "x-large": "",
      "href": _vm.testUrl,
      "target": "_blank"
    }
  }, [_vm._v(" Start Test ")]), _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-text-field', {
    staticClass: "mr-2",
    attrs: {
      "readonly": "",
      "value": _vm.testUrl,
      "label": "Test URL"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.copyToClipboard
    }
  }, [_vm._v(" Copy URL ")])], 1)], 1)], 1) : _vm.successfulInvite ? _c('v-card', {
    attrs: {
      "elevation": "0",
      "height": "800"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center"
  }, [_c('v-card-title', {
    staticClass: "text-h5 justify-center"
  }, [_c('v-icon', {
    staticClass: "mr-4 mb-5",
    attrs: {
      "color": "primary",
      "x-large": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiCheckDecagram) + " ")]), _c('div', {
    staticStyle: {
      "word-break": "normal",
      "overflow-wrap": "normal",
      "line-height": "1.6"
    }
  }, [_c('h2', [_c('strong', [_vm._v("Success!")])]), _c('p', [_vm._v(" We've sent an "), _c('strong', [_vm._v("invitation")]), _vm._v(" to "), _c('strong', [_vm._v(_vm._s(_vm.email))]), _vm._v(" from "), _c('strong', [_vm._v("hello@codedrills.io")]), _vm._v(". ")]), _c('h3', [_c('strong', [_vm._v("Next Steps:")])]), _c('ul', {
    attrs: {
      "align": "left"
    }
  }, [_c('li', [_vm._v(" Please check your "), _c('strong', [_vm._v("inbox")]), _vm._v(" (and spam folder just in case) for the test invite. ")]), _c('li', [_vm._v(" Prepare for the test using our "), _c('strong', [_c('a', {
    attrs: {
      "href": "/signup/test-gbtf4z/testInvite"
    }
  }, [_vm._v("sample")])]), _vm._v(". ")]), _c('li', [_vm._v(" Ensure you have a stable internet connection and chrome browser installed. ")])]), _c('p', [_vm._v(" If you have any issues or questions, please contact us at "), _c('strong', [_c('a', {
    attrs: {
      "href": "mailto:hello@codedrills.io"
    }
  }, [_vm._v("hello@codedrills.io")])]), _vm._v(". ")]), _c('p', [_vm._v(" We're "), _c('strong', [_vm._v("excited")]), _vm._v(" to see your skills in action! ")])])], 1)], 1)], 1)], 1) : _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', [_vm._v(" Candidate Details: ")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "placeholder": "Enter Name",
      "outlined": "",
      "rules": [function (v) {
        return !!v || 'Name is required';
      }],
      "counter": 50
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_vm.name ? _c('v-icon', {
          attrs: {
            "color": "green"
          }
        }, [_vm._v(_vm._s(_vm.mdiCheckCircle))]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Email",
      "placeholder": "email@domain.com",
      "outlined": "",
      "rules": _vm.emailRules
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_vm.email && /.+@.+\..+/.test(_vm.email) ? _c('v-icon', {
          attrs: {
            "color": "green"
          }
        }, [_vm._v(_vm._s(_vm.mdiCheckCircle))]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "justify-center pb-8"
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.showError ? _c('v-alert', {
    attrs: {
      "type": "error",
      "dense": "",
      "dismissible": "",
      "icon": _vm.mdiAlert
    },
    on: {
      "input": _vm.closeError
    }
  }, [_vm._v(" " + _vm._s(_vm.submitError) + " ")]) : _vm._e()], 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "offset-md": 4
    }
  }, [_c('v-btn', {
    staticClass: "rounded-md text-capitalize pa-5 white--text font-weight-medium text-h6",
    attrs: {
      "color": "success",
      "disabled": !_vm.form || _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validateForm
    }
  }, [_vm._v(" Submit ")])], 1)], 1), _vm.showError ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" You've already been "), _c('strong', [_vm._v("invited")]), _vm._v(" to the test with the email "), _c('strong', [_vm._v(_vm._s(_vm.email))]), _vm._v(" from "), _c('strong', [_vm._v("hello@codedrills.io")]), _vm._v(". ")]), _c('h3', [_c('strong', [_vm._v("Next Steps:")])]), _c('ul', {
    attrs: {
      "align": "left"
    }
  }, [_c('li', [_vm._v(" Please check your "), _c('strong', [_vm._v("inbox")]), _vm._v(" (and spam folder just in case) for the test invite. ")]), _c('li', [_vm._v(" Prepare for the test using our "), _c('strong', [_c('a', {
    attrs: {
      "href": "/signup/test-gbtf4z/testInvite"
    }
  }, [_vm._v("sample")])]), _vm._v(". ")]), _c('li', [_vm._v(" Ensure you have a stable internet connection and chrome browser installed. ")])]), _c('p', [_vm._v(" If you have any issues or questions, please contact us at "), _c('strong', [_c('a', {
    attrs: {
      "href": "mailto:hello@codedrills.io"
    }
  }, [_vm._v("hello@codedrills.io")])]), _vm._v(". ")]), _c('p', [_vm._v(" We're "), _c('strong', [_vm._v("excited")]), _vm._v(" to see your skills in action! ")])])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }